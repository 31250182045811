import React, { useContext } from "react";
import parse from "html-react-parser";
import { Helmet } from "react-helmet";
import { graphql } from "gatsby";

import Layout from "../components/layout";
import Banner from "../components/Banner";
import Container from "../components/Container";
import PostTable from "../components/PostTable";

import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core";

import useUrl from "../hooks/useUrl";
import useLanguage from "../hooks/useLanguage";

import i18nContext from "../i18n-context";

import { mapMomentToPosts } from "../utils";

const SpeechPage = ({ data, pageContext }) => {
  const url = useUrl();
  const lang = useLanguage();
  const classes = useStyles();
  const i18n = useContext(i18nContext)[lang];

  const { title, translated, excerpt, featuredImage } = pageContext;
  const sourceUrl = featuredImage?.node?.sourceUrl || "";

  const {
    allWpSpeech: { nodes },
  } = data;

  const speeches = mapMomentToPosts(
    nodes,
    "contentGenericGroup.publicationDate"
  ).map((node) => ({
    ...node,
    uri: node.speechPdfFiles,
    contentGenericGroup: { ...node.contentGenericGroup },
  }));

  const ogDescription = excerpt ? parse(excerpt)[0]?.props?.children : "";

  return (
    <Layout pageTranslations={translated}>
      <Helmet>
        <title>{`${i18n.websiteName} - ${title}`}</title>

        {/* og tags for facebook and linkedin (except og:type and og:locale) */}
        <meta property="og:url" content={url} />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={ogDescription} />
        <meta property="og:image" content={sourceUrl} />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="800" />
        <meta
          property="og:locale"
          content={lang === "en" ? "en_US" : lang === "tc" ? "zh_TW" : "zh_CN"}
        />

        {/* og tags for twitter */}
        <meta name="twitter:card" content="summary" />
        {/* <meta name="twitter:site" content="@nytimes" />
        <meta name="twitter:creator" content="@SarahMaslinNir" /> */}
        <meta name="twitter:title" content={title} />
        <meta name="twitter:description" content={ogDescription} />
        <meta name="twitter:image" content={sourceUrl} />
      </Helmet>
      <Banner title={title} bannerUrl={sourceUrl} />
      <Container>
        <Typography
          variant="body1"
          className={classes.description}
          component="div"
          dangerouslySetInnerHTML={{ __html: excerpt }}
        />
        <PostTable posts={speeches} openPostsInNewTab />
      </Container>
    </Layout>
  );
};

const useStyles = makeStyles((theme) => ({
  description: {
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(10),
    fontFamily: "Open Sans",
    fontWeight: 400,
  },
}));

export const query = graphql`
  query getSpeechesQuery($locale: String) {
    allWpSpeech(
      sort: { fields: contentGenericGroup___publicationDate, order: DESC }
      filter: { locale: { locale: { eq: $locale } } }
    ) {
      nodes {
        title
        uri
        speechPdfFiles
        contentGenericGroup {
          publicationDate
        }
      }
    }
  }
`;

export default SpeechPage;
